import moment from "moment";
import 'moment/locale/fr';

/**
 * Permet de transformer un nombre de secondes en format date compréhensible
 * @param {*} timestamp 
 * @returns 
 */
export const dateFromTimestampToString = (timestamp) => {
  moment.locale('fr');
  const timestampGenerated = moment.unix(timestamp);

  return timestampGenerated.utcOffset('+0000').format("ll"); //L
};

export const dateFromTimestampToHtmlFormat = (timestamp) => {
  moment.locale('fr');
  const timestampGenerated = moment.unix(timestamp);

  return timestampGenerated.utcOffset('+0000').format("YYYY-MM-DD"); //L
};