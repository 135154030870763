import React from 'react';

function Input({name, id, type, label, value, onChange, error=null, required=true}) {
  return (
    <div className="mb-2">
      <div className='flex'>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        {required ? (
          <label className='text-red-500'> *</label>
        ) : null}
      </div>

      
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className={`mt-1 py-1.5 px-2 block w-full  rounded border-gray-300 border focus:border-primary ${
          error ? 'border-red-500' : ''
        }`}
      />
      {error && (
        <p className="text-red-500 text-sm mt-1">{error}</p>
      )}
    </div>
  );
}

export default Input;