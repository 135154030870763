import { doc, getFirestore, getDoc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { useUser } from '../contexts/UserContext';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';

const db = getFirestore(app);

function ChooseCoownership() {

  let user = useUser(); 

  const [coownerships, setCoownerships] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    //on boucle sur l'ensemble des coownership de l'utilisateur 
    user.user.userData.coownershipId.map((coownership) => {
      //on execute la query pour récupérer les infos de la coownership 
      getCoownership(coownership.coownershipReference.path); 
    })
  }, []); 



  const getCoownership = async (coownershipPath) => {
    
    const docRef = doc(db, coownershipPath);

    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {

      let obj = {
        id: docSnap.id, 
        address: docSnap.data().address,
        zip: docSnap.data().zip,
        city: docSnap.data().city,
        image: docSnap.data().image, 
        name: docSnap.data().name,
      }
  
      setCoownerships(coownerships => [...coownerships, obj]);
    }
  }


  const updateLastCoownership = async (coownershipId) => {
    console.log(user)
    const docRefCoownership = doc(db, "coownership/"+coownershipId); //on créé la référence qui sera update dans le champs lastCoownershipLoaded

    //on envoie les données sur firestore 
    const userDoc = doc(db, "users/"+user.user.user.uid);

    await updateDoc(userDoc, {
      lastCoownershipLoaded: docRefCoownership,
    });

    let userData = user.user.userData; 

    userData.lastCoownershipLoaded = docRefCoownership; 

    //on update le contexte de l'utilisateur 
    user.setUser({user: user.user.user, userData: userData});


    navigate('/dashboard'); // Redirigez l'utilisateur vers la home
  } 


  /**
   * un item de la liste
   * @param {*} param0 
   * @returns 
   */
  function ListItem(coownership) {
    return (
      <div className="flex items-center p-2 border-b border-gray-300 cursor-pointer" onClick={() => updateLastCoownership(coownership.id)}>
        <div className="w-12 h-12 rounded-full overflow-hidden mr-4 border-1 border-primary">
          <img src={coownership.image ? coownership.image : "https://firebasestorage.googleapis.com/v0/b/trustymo-72f96.appspot.com/o/coownershipPicture%2Fbernard-hermant-KqOLr8OiQLU-unsplash.jpg?alt=media&token=620baa2d-727f-4e07-b2f0-396dce037390"} alt={coownership.name} className="w-full h-full" />
        </div>
        <div className='flex-grow'>
          <div className="text-primary text-lg">{coownership.name}</div>
          <div className="text-sm">{coownership.address + " " + (coownership.zip ? coownership.zip : "") + " " + (coownership.city ? coownership.city : "")}</div>
        </div>
        
        {user.user.userData.lastCoownershipLoaded.path == "coownership/"+coownership.id ? (
          <div className="text-secondary text-2xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        ) : null }
      </div>
    );
  }

  return (
    <div className='min-h-screen flex  justify-center items-center '>
      <div className=' md:w-5/6 md:h-5/6 p-8 justify-center'>
        {coownerships.length > 0 ? (
          coownerships.map((coownership, key) => {
            return(
              <div>
                {ListItem(coownership)}
              </div>
            )
          })
        ) : (
          <Loader />
        )}
      </div>
    </div> 
  );
}

export default ChooseCoownership;