/**
 * Génère un code ou un mot de passe 
 * @param {*} length 
 * @param {*} uppercase 
 * @param {*} lowercase 
 * @param {*} number 
 * @param {*} symbols 
 * @returns 
 */
export const generateRandomCode = (length=10, uppercase=true, lowercase=true, number=true, symbols=false) => {

  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const numberChars = "0123456789";
  const symbolChars = '!@#$%^&*()_+-={}[]|:;"<>,.?/~';

  let passwordChars = "";
  let password = "";

  if (uppercase) {
    passwordChars += uppercaseChars;
  }

  if (lowercase) {
    passwordChars += lowercaseChars;
  }

  if (number) {
    passwordChars += numberChars;
  }

  if (symbols) {
    passwordChars += symbolChars;
  }

  const passwordLength = length;

  for (let i = 0; i < passwordLength; i++) {
    const randomIndex = Math.floor(Math.random() * passwordChars.length);
    password += passwordChars[randomIndex];
  }

  return password; 
};