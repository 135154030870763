import React from 'react';

const BottomMenu = () => {
  return (
    <footer className="bottom-0 left-0 right-0 bg-primary text-white py-2">
      <div className="container mx-auto flex justify-center">
        <ul className="md:flex space-x-4 space-y-2">
          <li className='ml-4 my-2'>
            <a href="https://trustymo.com/contactez-nous" target={"_blank"} className="hover:text-secondary transition duration-300">
              Contact
            </a>
          </li>
          <li>
            <a href="https://drive.google.com/file/d/1e59Yq4eHXoFpt3aMjkMsPQwLKizhgfsY/view?usp=share_link" target={"_blank"} className="hover:text-secondary transition duration-300">
              CGU
            </a>
          </li>
          <li>
            <a href="https://drive.google.com/file/d/1qkhRqxNkjcDnyQNrifiM-O4TsJdUhQto/view?usp=share_link" target={"_blank"} className="hover:text-secondary transition duration-300">
              Politique de confidentialité
            </a>
          </li>
          <p>@trustymo 2023</p>
        </ul>
      </div>
    </footer>
  );
};

export default BottomMenu;
