import { collection, doc, getFirestore, addDoc, updateDoc, Timestamp } from 'firebase/firestore';
import React, { useState } from 'react';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Input from '../components/inputs/Input';
import Loader from '../components/Loader';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { useUser } from '../contexts/UserContext';
import { dateFromTimestampToHtmlFormat } from '../librairies/Dates';

const db = getFirestore(app);

function AddProvider({onClose, provider=null}) {

  //contexte de l'utilisateur connecté
  const user = useUser();

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  
  const [address, setAddress] = useState(provider !== null ? provider.address : "");
  const [zip, setZip] = useState(provider !== null ? provider.zip : "");
  const [city, setCity] = useState(provider !== null ? provider.city : "");
  const [email, setEmail] = useState(provider !== null ? provider.email : "");
  const [phone, setPhone] = useState(provider !== null ? provider.phone : "");
  const [name, setName] = useState(provider !== null ? provider.name : "");
  const [type, setType] = useState(provider !== null ? provider.type : "");
  const [dateBeginContract, setDateBeginContract] = useState(provider !== null ? dateFromTimestampToHtmlFormat(provider.dateBeginContract) : ""); 


  const [errors, setErrors] = useState({
    address: '',
    city: '',
    zip: '',
    phone: '',
    email: '',
    name: '',
    type: '',
    dateBeginContract: '',
  });

  const resetState = () => {
    setError("");
    setAddress("");
    setCity("");
    setZip("");
    setDateBeginContract("");
    setEmail("");
    setName("");
    setPhone(""); 
    setType("");
  }

  const handleSubmit = async (e)  => {
    e.preventDefault();
    console.log("submit provider");
    
    // Validation des champs
    const newErrors = {};

    if (!address) {
      newErrors.address = "L'adresse est requise.";
    }

    if (!city) {
      newErrors.city = "La ville est requise.";
    }

    if (!zip) {
      newErrors.zip = "Le code postal est requis.";
    }

    if (!phone) {
      newErrors.phone = "Le numéro de téléphone est requis.";
    }

    if (!email) {
      newErrors.email = "L'email est requis.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "L'email est invalide.";
    }

    if (!name) {
      newErrors.name = "Le nom de la société est requis.";
    }

    if (!type) {
      newErrors.type = "Le type de service proposé par la société est requis.";
    }

    if (!dateBeginContract) {
      newErrors.dateBeginContract = "La date de début de contrat est requise.";
    }

    if (Object.keys(newErrors).length === 0) {
      console.log("Données du formulaire valides.");

      try{
        setLoader(true);
        
        if(provider !== null){ //on update

          //on envoie les données sur firestore 
          const providerDoc = doc(db, user.user.userData.lastCoownershipLoaded.path+"/providers/"+provider.id);

          await updateDoc(providerDoc, {
            address: address,
            city:city,
            zip:zip, 
            dateBeginContract: Timestamp.fromDate(new Date(dateBeginContract)), 
            email:email,
            phone:phone,
            name:name,
            type:type
          });

        }else{
          //on envoie les données sur firestore 
          const providersCollection = collection(db, user.user.userData.lastCoownershipLoaded.path+"/providers");

          await addDoc(providersCollection, {
            address: address,
            city:city,
            zip:zip, 
            dateBeginContract: Timestamp.fromDate(new Date(dateBeginContract)), 
            email:email,
            phone:phone,
            name:name,
            type:type
          });
        }


        setLoader(false);
        //on remet tous les states par défaut
        resetState(); 

        //on close la popup
        onClose(); 

      } catch(err){
        console.log(err)
        setError("Une erreur est survenue, impossible d'ajouter le prestataire");
        setLoader(false);
      }
    } else {
      // Afficher les erreurs
      setErrors(newErrors);
    }
  };


  return (
    <div id="authentication-modal" tabindex="-1" aria-hidden="true" className="mx-auto w-full fixed inset-0 flex items-center justify-center overflow-auto">
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button type="button" onClick={onClose} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Ajouter un prestataire</h3>

            <form onSubmit={handleSubmit}>

              <div className='mb-6'>


                <div className='border-b border-gray mt-0.5 mb-2'></div>
                  <Input id={"name"} label={"Nom de la société"} type={"text"} name={"name"} value={name} onChange={(event) => setName(event.target.value)} error={errors.name} />
                  <Input id={"type"} label={"Type de services"} type={"text"} name={"type"} value={type} onChange={(event) => setType(event.target.value)} error={errors.type} />
                  <Input id={"address"} label={"Adresse"} type={"text"} name={"address"} value={address} onChange={(event) => setAddress(event.target.value)} error={errors.address} />
                  <Input id={"zip"} label={"Code Postal"} type={"text"} name={"zip"} value={zip} onChange={(event) => setZip(event.target.value)} error={errors.zip} />
                  <Input id={"city"} label={"Ville"} type={"text"} name={"city"} value={city} onChange={(event) => setCity(event.target.value)} error={errors.city} />
                  <Input id={"email"} label={"Email du prestataire"} type={"email"} name={"email"} value={email} onChange={(event) => setEmail(event.target.value)} error={errors.email} />
                  <Input id={"phone"} label={"Téléphone du prestataire"} type={"phone"} name={"phone"} value={phone} onChange={(event) => setPhone(event.target.value)} error={errors.phone} />
                  <Input id={"date"} label={"Date de début de contrat"} type={"date"} name={"date"} value={dateBeginContract} onChange={(event) => setDateBeginContract(event.target.value)} error={errors.dateBeginContract} />
                </div>

                <div className="text-center">
                  <PrimaryButton type={"submit"} value={provider !== null ? "Modifier" : "Ajouter"} loading={loader} />
                </div>

                {error !== "" ? (
                  <p className='justify-center flex my-1 text-red-500'>{error}</p>
                ) : null}
              
            </form>

          </div>
        </div>
      </div>
    </div> 
  );
}

export default AddProvider;