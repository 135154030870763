import React from 'react';

function FileInput({id, name, label, file, onChange, error=null, fileAccepted="image/*", type="image"}) {
  return (
    <div className="mb-2">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mx-auto mt-0.5 p-4 bg-white rounded shadow">
        <input
          id={id}
          name={name}
          type="file"
          accept={fileAccepted}
          onChange={onChange}
          className="mb-4"
        />
        {type== "image" ? (
          file && (
            <div className="mb-4">
              <img
                src={file}
                alt={name}
                className="w-96 h-auto rounded"
              />
            </div>
          )
        ) : null }

        {type== "pdf" ? (
          file && (
            <div className="mb-4">
              <p className=''>{file}</p>
            </div>
          )
        ) : null }

      </div>

      {error && (
        <p className="text-red-500 text-sm mt-1">{error}</p>
      )}
    </div>
  );
}

export default FileInput;