import React, { useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getFirestore, collection, doc, updateDoc, onSnapshot, deleteDoc } from 'firebase/firestore'; 
import Loader from '../components/Loader';
import { useUser } from '../contexts/UserContext';
import { dateFromTimestampToString } from '../librairies/Dates';
import PrimaryButton from '../components/buttons/PrimaryButton';
import AddProvider from './AddProvider';
import DeleteModal from '../components/modals/DeleteModal';

const db = getFirestore(app);

const ListProviders = () => {

  const [providers, setProviders] = useState([]); 
  const [loader, setLoader] = useState(true);
  const [showAddProvider, setShowAddProvider] = useState(false); 
  const [showUpdateProvider, setShowUpdateProvider] = useState(false); 
  const [showDeleteModal, setShowDeleteModal] = useState(false); 
  const [elementToDelete, setElementToDelete] = useState(null); 
  const [elementToUpdate, setElementToUpdate] = useState(null);

  //contexte de l'utilisateur connecté
  const user = useUser();


  useEffect(() => {
    const collectionRef = collection(db, user.user.userData.lastCoownershipLoaded.path+"/providers");

    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {

      if(snapshot.docs.length > 0){
        
        let arr = [];

        snapshot.docs.map((provider) => {
          let obj = {
            id: provider.id, 
            name: provider.data().name, 
            type: provider.data().type, 
            email: provider.data().email,
            phone: provider.data().phone,
            dateBeginContract: provider.data().dateBeginContract.seconds,
            address: provider.data().address,
            zip: provider.data().zip,
            city:provider.data().city
          }

          arr.push(obj);

        });
        
        setProviders(arr);
      }

      setLoader(false);
    });

    return () => {
      console.log("unsubscribe providers");
      unsubscribe();
    }
  }, []); 

  useEffect(() => {
    if(elementToDelete != null){
      setShowDeleteModal(true); 
    }
  }, [elementToDelete]);

  useEffect(() => {
    if(elementToUpdate != null){
      setShowUpdateProvider(true); 
    }
  }, [elementToUpdate]);


  useEffect(() => {
    if(showDeleteModal === false){
      setElementToDelete(null);
    }
  }, [showDeleteModal]);


  useEffect(() => {
    if(showUpdateProvider === false){
      setElementToUpdate(null);
    }
  }, [showUpdateProvider]);


  /**
   * On supprime un prestataire
   */
  const deleteProvider = async () => {
    if(elementToDelete !== null){
      const docRef = doc(db, user.user.userData.lastCoownershipLoaded.path+"/providers/"+elementToDelete);
      await deleteDoc(docRef);

      setShowDeleteModal(false);

    }
  }





  return (
    <div>
      <a href="#" onClick={() => setShowAddProvider(true)} className="justify-end flex mb-4">
        <PrimaryButton type={"button"} value={"Ajouter un prestataire"} loading={false} />
      </a>
      
      {loader ? (
        <Loader />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                    Activité
                </th>
                <th scope="col" className="px-6 py-3">
                    Nom société
                </th>
                <th scope="col" className="px-6 py-3">
                    Email
                </th>
                <th scope="col" className="px-6 py-3">
                    Téléphone
                </th>
                <th scope="col" className="px-6 py-3">
                    Date début contrat
                </th>
                <th scope="col" className="px-6 py-3">
                    Action
                </th>
              </tr>
            </thead>
            <tbody>
              {providers.map((provider, key) => {
                return (
                  <tr key={key} className={key % 2 === 0 ? "bg-white border-b dark:bg-gray-900 dark:border-gray-700" : "border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700"}>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {provider.type}
                    </th>
                    <td className="px-6 py-4">
                      {provider.name}
                    </td>
                    <td className="px-6 py-4">
                      {provider.email}
                    </td>
                    <td className="px-6 py-4">
                      {provider.phone}
                    </td>
                    <td className="px-6 py-4">
                      {dateFromTimestampToString(provider.dateBeginContract)}
                    </td>
                    <td className="px-6 py-4">
                      <div className='flex gap-4'>
                        <a href="#" onClick={() => setElementToUpdate(provider)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Modifier</a>
                        <a href="#" onClick={() => setElementToDelete(provider.id)} className="font-medium text-red-600 dark:text-red-500 hover:underline">Supprimer</a>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}

      {showAddProvider ? (
        <AddProvider onClose={() => setShowAddProvider(false)}/>
      ) : null }

      {showUpdateProvider ? (
        <AddProvider onClose={() => setShowUpdateProvider(false)} provider={elementToUpdate} />
      ) : null }

      {showDeleteModal ? (
        <DeleteModal closeAction={() => setShowDeleteModal(false)} confirmAction={() => deleteProvider()}/>
      ) : null }
      
    </div>
  );
};

export default ListProviders;
