import React from 'react';

const CheckboxInput = ({ id, label, name, checked, onChange }) => {
  return (
    <div className="mb-4">
      <label className="inline-flex items-center">
        <input
          id={id}
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          className="form-checkbox h-5 w-5 text-blue-600"
        />
        <span className="ml-2">{label}</span>
      </label>
    </div>
  );
};

export default CheckboxInput;