import React from 'react';

function TextModal({onClose, text}) {
  return (
    <div id="popup-modal" tabindex="-1" class="mx-auto w-full fixed inset-0 flex items-center justify-center overflow-auto">
      <div class="relative w-full max-w-md max-h-full">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button onClick={onClose} type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
          <div class="p-6 text-left pt-16">
            <p class="mb-5 text-md font-normal text-gray-500 dark:text-gray-400">{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextModal;