import { getDoc, getDocs } from 'firebase/firestore'; 

// Récupérez le document Firestore
export const fetchData = async (docRef) => {
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // Le document existe, vous pouvez accéder à ses données
      return docSnap.data();
    } else {
      // Le document n'existe pas
      console.log('Document not found!');
      return null; 
    }
  } catch (error) {
    console.error('Error fetching document:', error);
    return null; 
  }
};

// Récupérez les document Firestore d'une collection
export const fetchDocs = async (collectionRef) => {
  try {
    const docs = await getDocs(collectionRef);
    
    return docs.docs;
    /*if (docSnap.exists()) {
      // Le document existe, vous pouvez accéder à ses données
      return docSnap.data();
    } else {
      // Le document n'existe pas
      console.log('Document not found!');
      return null; 
    }*/
  } catch (error) {
    console.error('Error fetching document:', error);
    return null; 
  }
};