import React from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const auth = getAuth(app);

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut(); // Déconnectez l'utilisateur
      navigate('/login'); // Redirigez l'utilisateur vers la page de connexion après la déconnexion
    } catch (error) {
      console.error('Erreur lors de la déconnexion :', error);
    }
  };

  return (
    <div className="text-white hover:text-secondary transition duration-300">
      <button onClick={handleLogout}>Déconnexion</button>
    </div>
  );
};

export default Logout;