import React from 'react';

function Loader() {
  return (
    <div className="flex items-center justify-center">
      <div className="animate-spin rounded-full border-t-2 border-b-2 border-primary h-12 w-12"></div>
    </div>
  );
}

export default Loader;