
import React from 'react';
import './App.css';
import './css/output.css';
import Login from './authentication/Login';


import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useUser } from './contexts/UserContext';
import Menu from './menu/Menu';
import BottomMenu from './menu/BottomMenu';
import Dashboard from './dashboard/Dashboard';

import { isCoownership } from './contexts/UserContext';
import ChooseCoownership from './coownership/ChooseCoownership';

function App() {

  const user = useUser();

  console.log("Le contexte de l'utilisateur"); 
  console.log(user);

  return (
    <Router>
      
      <Menu connected={user.user != null}/>

      {/* Contenu de la page */}
      
      {user.user == null ? (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/" element={<Dashboard />} />
          {isCoownership(user.user) ? (
            <Route path='/coownership' element={<ChooseCoownership />}/>
          ) : null }
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      )}

      

      <BottomMenu />
    </Router>
  );
}


export default App;
