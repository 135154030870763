import React, { useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getFirestore, collection, onSnapshot, query, where, orderBy, updateDoc, doc } from 'firebase/firestore'; 
import Loader from '../components/Loader';
import { useUser } from '../contexts/UserContext';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import styled from "styled-components";
import PrimaryButton from '../components/buttons/PrimaryButton';
import AddBillboard from './AddBillboard';
import DeleteModal from '../components/modals/DeleteModal';
import { dateFromTimestampToString } from '../librairies/Dates';

const db = getFirestore(app);

const ListBillboard = () => {

  const [billboards, setBillboards] = useState([]); 
  const [loader, setLoader] = useState(true);
  const [showAddBillboard, setShowAddBillboard] = useState(false); 
  const [showArchiveModal, setShowArchiveModal] = useState(false); 
  const [elementToArchive, setElementToArchive] = useState(null); 

  //contexte de l'utilisateur connecté
  const user = useUser();


  useEffect(() => {
    const collectionRef = query(collection(db, user.user.userData.lastCoownershipLoaded.path+"/billboard"), where("status", "==", "accepted"), orderBy("dateSave", "desc"));

    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {

      if(snapshot.docs.length > 0){
        
        let arr = [];

        snapshot.docs.map((billboard) => {
          let obj = {
            id: billboard.id, 
            title: billboard.data().title, 
            description: billboard.data().description, 
            firstname: billboard.data().firstnameCreator,
            lastname: billboard.data().lastnameCreator,
            date: billboard.data().dateSave.seconds,
            status: billboard.data().status,
            typeMedia: billboard.data().typeMedia,
            linkMedia: billboard.data().linkMedia,
          }

          arr.push(obj);

        });
        
        setBillboards(arr);
      }else{
        setBillboards([]); 
      }

      setLoader(false);
    });

    return () => {
      console.log("unsubscribe billboards");
      unsubscribe();
    }
  }, []); 


  useEffect(() => {
    if(elementToArchive !== null){
      setShowArchiveModal(true); 
    }
  }, [elementToArchive]);

  useEffect(() => {
    if(showArchiveModal === false){
      setElementToArchive(null);
    }
  }, [showArchiveModal]);

  /**
  * On archive un billboard
  */
  const archiveBillboard = async () => {
  if(elementToArchive !== null){
    const docRef = doc(db, user.user.userData.lastCoownershipLoaded.path+"/billboard/"+elementToArchive);
    await updateDoc(docRef, {
      status: "archived"
    });

    setShowArchiveModal(false);

  }
}

  return (
    <div>      

      <a href="#" onClick={() => setShowAddBillboard(true)} className="justify-end flex mb-4">
        <PrimaryButton type={"button"} value={"Ajouter un affichage"} loading={false} />
      </a>

      {loader ? (
        <Loader />
      ) : (
        billboards.length > 0 ? (
          <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={60}
          visibleSlides={1}
          totalSlides={billboards.length}
        >
          <Slider >
            {billboards.map((billboard, key) => {
              return(
                <Slide index={key} key={key}>
                  <div className='border rounded-8 shadow'>
                    <div className='flex'>
                      <h3 className='text-center text-lg p-4 font-bold flex-1'>{billboard.title}</h3>
                      <a href={"#"} onClick={() => setElementToArchive(billboard.id)} className=' p-4'>
                        <p className='text-red-500 font-bold'>Archiver</p>
                      </a>
                    </div>

                    
                    {billboard.typeMedia !== undefined && billboard.typeMedia !== null ? (
                      billboard.typeMedia === "image" ? (
                        <a href={billboard.linkMedia} target='_blank'>
                          <img src={billboard.linkMedia} alt={billboard.title} className='p-4 rounded-xl border m-auto object-contain h-64 w-64'/>
                        </a>
              
                      ) : (
                        <a href={billboard.linkMedia} target='_blank'>
                          <p className='text-blue-600 p-4'>Afficher le pdf</p>
                        </a>
                      )
                    ) : (
                      null
                    )}
                    <p className='text-left px-4 py-2 whitespace-pre-wrap'>{billboard.description}</p>
                    <p className='text-left px-4 py-2'>{"Affichage créé par "+billboard.firstname+" "+billboard.lastname+" le "+dateFromTimestampToString(billboard.date)}</p>
                    
                  </div>
                
                </Slide>
              )
            })}
          </Slider>
          <Wrapper>
            <div className="controls">
              <ButtonBack className="btn-arrow "> {/*reverse-arrow*/}
                {/*<img src={Arrow} alt="arrow" />*/}
                <p className='text-cyan-600'>Précédent</p>
              </ButtonBack>
              <DotGroup className="dot-group" />
              <ButtonNext className="btn-arrow">
                {/*<img src={Arrow} alt="arrow" />*/}
                <p className='text-cyan-600'>Suivant</p>
              </ButtonNext>
            </div>
          </Wrapper>

        </CarouselProvider>
        ) : (
          <p className='text-center'>Aucun affichage pour le moment</p>
        )
      )}

      {showAddBillboard ? (
        <AddBillboard onClose={() => setShowAddBillboard(false)}/>
      ) : null }

      {showArchiveModal ? (
        <DeleteModal closeAction={() => setShowArchiveModal(false)} confirmAction={() => archiveBillboard()} type={"archive"}/>
      ) : null }
    </div>
  );
};


const Wrapper = styled.div`
  .controls {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-arrow {
      border: none;
      background: none;
      padding: 11px 20px;
    }

    .reverse-arrow {
      transform: rotateY(180deg);
    }

    .dot-group {
      display: flex;
      align-items: center;
      justify-content: center;

      .carousel__dot {
        width: 8px;
        height: 8px;
        border: none;
        border-radius: 50%;
        margin: 0 4px;
        padding: 0;
        background-color: #c3c4ca;
      }

      /* This class is found in DotGroup from pure-react-carousel */
      /* We need to override it to add our styles */
      .carousel__dot--selected {
        width: 16px;
        height: 8px;
        border-radius: 10px;
        background-color: #E49680;
        transition: background 0.4s ease;
      }
    }
  }
`;

export default ListBillboard;

