
import React, { useEffect, useState } from 'react';
import { app } from '../firebase'; // Assurez-vous d'importer votre configuration Firebase
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'

const auth = getAuth(app);

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const [error, setError] = useState(null);


  const [loginForm, setLoginForm] = useState(true); //permet de switcher avec le form de réinitialisation de mot de passe

  const navigate = useNavigate();


  useEffect(() => {
    setError("");
  }, [loginForm]); 

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleAuth = async () => {
    try {
      
      const result = await signInWithEmailAndPassword(auth, email, password);

      navigate('/dashboard'); // Redirigez l'utilisateur vers la home
      
    } catch (error) {
      setError(handleErrorSignin(error.code));
    }
  };

  const handleForgottenPassword = async () => {
    try {
      
      const result = await sendPasswordResetEmail(auth, email);
      alert("Un email vous a été envoyé pour réinitialiser votre mot de passe"); 

      setLoginForm(true);
      
      
    } catch (error) {
      setError(handleErrorSignin(error.code));
    }
  };

  /**
   * Gère les erreurs retournées par Firebase à la connexion 
   * @param {*} errorCode 
   * @returns 
   */
  const handleErrorSignin = (errorCode) => {
    switch(errorCode) {
      case 'auth/wrong-password':
        return "Le mot de passe est incorrect"; 
      case 'auth/invalid-email': 
        return "L'adresse email n'est pas dans un bon format";
      case 'auth/user-not-found':
        return "Cette adresse email ne correspond à aucun compte"; 
      case 'auth/user-disabled':
        return "Votre compte a été bloqué, impossible de s'y connecter"; 
      default: 
        return "Impossible de se connecter";
    }
  }

  /**
   * Gère l'affichage ou non du password
   */
  const handleToggle = () => {
    if (type==='password'){
       setIcon(eye);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      {loginForm ? (
        <div className="bg-white p-8 rounded-lg shadow-md w-96">
          <h2 className="text-2xl font-semibold mb-4 text-primary">Connexion</h2>
          <input
            type="email"
            placeholder="Adresse e-mail"
            className="w-full p-2 mb-4 border rounded-md"
            value={email}
            onChange={handleEmailChange}
          />
          <div class="mb-4 flex">
            <input
              type={type}
              placeholder="Mot de passe"
              className="w-full p-2 border rounded-md"
              value={password}
              onChange={handlePasswordChange}
            />
            <span class="flex justify-around items-center" onClick={handleToggle}>
              <Icon class="absolute mr-10" icon={icon} size={24}/>
            </span>
          </div>
          <button
            onClick={handleAuth}
            className={`w-full bg-primary text-white p-2 rounded-md hover:bg-opacity-80`}
          >
            Se connecter
          </button>

          <a onClick={() => setLoginForm(false)} className="cursor-pointer text-cyan-500 text-sm underline mt-2 italic flex justify-center">
            Mot de passe oublié
          </a>

          {error && (
            <p className="text-red-500 text-sm mt-2">{error}</p>
          )}
        </div>
      ) : (
        <div className="bg-white p-8 rounded-lg shadow-md w-96">
          <h2 className="text-2xl font-semibold mb-4 text-primary">Mot de passe oublié</h2>
          <input
            type="email"
            placeholder="Adresse e-mail"
            className="w-full p-2 mb-4 border rounded-md"
            value={email}
            onChange={handleEmailChange}
          />

          <button
            onClick={handleForgottenPassword}
            className={`w-full bg-primary text-white p-2 rounded-md hover:bg-opacity-80`}
          >
            Réinitialiser mon mot de passe
          </button>

          <a onClick={() => setLoginForm(true)} className="cursor-pointer text-cyan-500 text-sm underline mt-2 italic flex justify-center">
            Connexion
          </a>

          {error && (
            <p className="text-red-500 text-sm mt-2">{error}</p>
          )}
        </div>
      ) }

    </div>
  );
}

export default Login;
