// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA-ijRVboq-rqugxjMdBk24Kx_OlW5W8Ys",
  authDomain: "trustymo-72f96.firebaseapp.com",
  projectId: "trustymo-72f96",
  storageBucket: "trustymo-72f96.appspot.com",
  messagingSenderId: "688932967983",
  appId: "1:688932967983:web:21cc5932a503723e1498da",
  measurementId: "G-0MJWH5G7BX"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);