import React, { createContext, useContext, useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getAuth} from "firebase/auth";
import { getFirestore, doc, getDoc, query, onSnapshot } from 'firebase/firestore'; 

const auth = getAuth(app);
const db = getFirestore(app);

// Créez un contexte utilisateur
const UserContext = createContext();

// Créez un composant Context Provider
export function UserProvider({ children }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Utilisez Firebase pour surveiller l'état de l'utilisateur
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        //setUser(authUser);

        console.log("onAuthStateChanged"); 
        console.log(authUser.uid);

        // Remplacez 'votre_collection' par le nom de votre collection Firestore
        // et 'votre_document' par l'ID du document que vous souhaitez récupérer.
        const docRef = doc(db, 'users', authUser.uid);

        let data = await fetchUser(docRef); 


        //on vérifie qu'il s'agit bien d'un compte de type "admin" ou "copropriete"
        if(data.status === "copropriete" || data.status === "admin"){


          let obj = {user: authUser, userData: data};

          setUser(obj);
        }else {
          setUser(null);

          await auth.signOut(); // Déconnectez l'utilisateur
        }
        

      } else {
        setUser(null);
      }
    });

    // Nettoyez l'effet lors du démontage du composant
    return () => unsubscribe();
  }, []);


  return (
    <UserContext.Provider value={{user:user, setUser: setUser}}>
      {children}
    </UserContext.Provider>
  );
}


// Récupérez le document Firestore
const fetchUser = async (docRef) => {
  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // Le document existe, vous pouvez accéder à ses données
      return docSnap.data();
    } else {
      // Le document n'existe pas
      console.log('Document not found!');
      return null; 
    }
  } catch (error) {
    console.error('Error fetching document:', error);
    return null; 
  }
};


// Créez un hook personnalisé pour accéder au contexte utilisateur
export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser doit être utilisé à l'intérieur d'un UserProvider");
  }
  return context;
}


export const isAdmin = (user) => {
  console.log(user);
  return user.userData.status === "admin";
}

export const isCoownership = (user) => {
  console.log(user)
  return user.userData.status === "copropriete";
}