import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const storage = getStorage();


export const handleUpload = async (folder, file) => {
  if (file) {

    // Create a child reference
    const storageRef = ref(storage, folder+`${file.name}`);

    let urlFile = null; 

    return uploadBytes(storageRef, file).then(async (snapshot) => {
      console.log('Uploaded a blob or file!');

      console.log(snapshot);
      urlFile = await getDownloadURL(snapshot.ref);

      console.log('URL de téléchargement du file :', urlFile);

      return urlFile; 

    }).catch((err) => {
      console.log(err);
      return null;
    });
  } else { 
    return null 
  }
};